import env from "../../env";
import { removeEmail, saveEmail } from "../../utils/localstorage";
import {
  extractAuthParams,
  authenticate,
  getSession,
  destroySession,
} from "../ory/auth";
import { hydraAdmin } from "./index";
export const getLoginRequest = async (challenge, session = undefined) => {
  try {
    const { data: body } = await hydraAdmin.getOAuth2LoginRequest({
      loginChallenge: challenge
    });
    const user = session || (await getSession());

    if (!session) {
      return {
        challenge: challenge,
        session: user,
        client: body?.client,
      };
    }

    if (body.skip || user?.active) {
      const subject = user?.identity?.traits?.email[0] || body?.subject;
      const acceptRequest = await hydraAdmin.acceptOAuth2LoginRequest({
        loginChallenge: challenge,
        acceptOAuth2LoginRequest: {
          subject: String(subject)
        }
      });
      if (
        acceptRequest &&
        acceptRequest.data &&
        acceptRequest.data.redirect_to
      ) {
        return {
          redirect_to: acceptRequest.data.redirect_to,
        };
      }
    }

    return {
      challenge: challenge,
      hint: body.oidc_context?.login_hint || "",
      action: body.request_url,
    };
  } catch (error) {
    throw error;
  }
};

export const submitWebAuthnLoginRequest = async (
  formData,
  flowId,
  challenge
) => {
  try {
    const response = await authenticate(flowId, formData);
    if (response?.session?.active) {
      saveEmail(formData?.identifier);
      formData.email = formData.identifier;
      formData.challenge = challenge;
      const loginRes = await acceptLogin(formData);
      return loginRes;
    }
  } catch (error) {
    const errorContext = error?.response?.data;
    if (errorContext?.ui?.messages[0]) {
      removeEmail();
      return {
        // errors: error?.response?.data?.ui?.messages,
        redirect_to: `${env.APP_URL}/login?flow=${errorContext?.id}&login_challenge=${challenge}`,
      };
    }
    if (errorContext?.id === "session_already_available") {
      console.log("Already Logged in");
    }

    throw error;
  }
};

export const submitLoginRequest = async (data, navigate) => {
  try {
    const { challenge, submit, email, password } = data;
    if (submit === "Cancel") {
      const { data: body } = await hydraAdmin.rejectOAuth2LoginRequest({
        loginChallenge: challenge,
        rejectOAuth2Request: {
          error: "access_denied",
          error_description: "The resource owner denied the request",
        }
      });
      if (body?.redirect_to) {
        return {
          redirect_to: body?.redirect_to,
        };
      }
    } else {
      const { flowId, ...formData } = await extractAuthParams(data);
      formData.identifier = email;
      formData.password = password;
      const response = await authenticate(flowId, formData);
      if (response?.session?.active) {
        saveEmail(email);
        const loginRes = await acceptLogin(data, navigate, response?.session);
        return loginRes;
      }
    }
  } catch (error) {
    const errorContext = error?.response?.data;
    if (errorContext?.ui?.messages[0]) {
      removeEmail();
      return { errors: error?.response?.data?.ui?.messages };
    }
    if (errorContext?.id === "session_already_available") {
      console.log("Already Logged in");
    }
    if (
      errorContext?.error?.id === "browser_location_change_required" &&
      errorContext?.redirect_browser_to
    ) {
      const { challenge } = data;
      const flow = errorContext?.redirect_browser_to?.split("?")[1];
      return {
        redirect_to: `${env.APP_URL}/login?${flow}&login_challenge=${challenge}`,
      };
    }
    throw error;
  }
};

export const acceptLogin = async (data, navigate, session) => {
  const { data: body } = await hydraAdmin.acceptOAuth2LoginRequest({
    loginChallenge: data?.challenge,
    acceptOAuth2LoginRequest: {
        subject: data?.email,
        remember: Boolean(data?.remember),
        remember_for: 2592000
      }
  });
  return { redirect_to: body?.redirect_to };
};

export const acceptLogoutRequest = async (challenge) => {
  try {
    const { data: body } = await hydraAdmin.acceptOAuth2LogoutRequest({logoutChallenge: challenge});
    if (body?.redirect_to) {
      await destroySession();
      return { redirect_to: body?.redirect_to };
    }
  } catch (error) {
    throw error;
  }
};
// export const getTokenFromAuthCode = async (authCode, redirectUri) => {
//   if (authCode) {
//     const formData = new FormData();
//     formData.set("client_id", config.OAUTH_CLIENT_ID);
//     formData.set("grant_type", "authorization_code");
//     formData.set("code", authCode);
//     formData.set("redirect_uri", redirectUri);
//     formData.set("client_secret", config.OAUTH_CLIENT_SECRET);
//     const raw = await fetch(`${config.HYDRA_PUBLIC_ENDPOINT}/oauth2/token`, {
//       method: "POST",
//       body: formData,
//     });
//     const json = await raw.json();
//     console.log(json);
//     console.log(json?.refresh_token);
//     await getAccessTokenFromRefreshToken(json?.refresh_token, redirectUri);
//   }
// };

// export const getAccessTokenFromRefreshToken = async (
//   refreshToken,
//   redirectUri
// ) => {
//   if (refreshToken) {
//     const formData = new FormData();
//     formData.set("client_id", config.OAUTH_CLIENT_ID);
//     formData.set("grant_type", "refresh_token");
//     formData.set("refresh_token", refreshToken);
//     formData.set("redirect_uri", redirectUri);
//     formData.set("client_secret", config.OAUTH_CLIENT_SECRET);

//     const raw = await fetch(`${config.HYDRA_PUBLIC_ENDPOINT}/oauth2/token`, {
//       method: "POST",
//       body: formData,
//     });
//     const json = await raw.json();
//     console.log(json);
//   }
// };

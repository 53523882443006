import { Configuration, OAuth2Api } from "@ory/hydra-client";
import config from "../../env";
const baseOptions = {
  withCredentials: true,

};

if (process.env.MOCK_TLS_TERMINATION) {
  baseOptions.headers = { "X-Forwarded-Proto": "https" };
}


export const hydraAdmin = new OAuth2Api(
  new Configuration({
    basePath: config.HYDRA_ADMIN_ENDPOINT,
    baseOptions: {
      ...baseOptions,
      headers: {
        Authorization: "Bearer " + config.ORY_TOKEN,
      },

    },
  })
);

export const hydraPublic = new OAuth2Api(
  new Configuration({
    basePath: config.HYDRA_PUBLIC_ENDPOINT,
    baseOptions,
  })
);

// export const hydraAdmin = new AdminApi(
//   new Configuration({
//     basePath: config.HYDRA_ADMIN_ENDPOINT,
//     baseOptions: {
//         ...baseOptions,
//       headers: {
//         Authorization: "Bearer " + config.ORY_TOKEN,
//       },

//     },
//   })
// );

// export const hydraPublic = new PublicApi(
//   new Configuration({
//     basePath: config.HYDRA_PUBLIC_ENDPOINT,
//     baseOptions,
//   })
// );

import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';

const Callback = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const error = params.get('error');
  const error_description = params.get('error_description');
  const code = params.get('code');
  useEffect(() => {
    const platform = getMobileOperatingSystem();
    const slug = window.location.href.split('accounts.enschede.me/').pop();
    if (code) {
      if (platform === 'ANDROID') {
        window.open(`intent://accounts.enschede.me/${slug}#Intent;scheme=enschede;package=enschede.me;end`);
      } else {
      //  if (platform === 'IOS') {
        window.open(`enschede://${slug}`);
      } 
      // else {
      //   navigate(`/errors?error=UNKNOWN_PLATFORM&error_description=unknown platform found!`);
      // }
    }
    if (error) {
      if (error === 'request_forbidden') {
        window.history.back();
        // navigate(`/errors?error=${error}&error_description=${error_description}`);
      } else {
        navigate(`/errors?error=${error}&error_description=${error_description}`);
      }
    }
  }, []);

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "ANDROID";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "IOS";
    }

    return "unknown";
  }
  return null;
}

export default Callback